<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">{{ releaseFormItem.article.name }}</h1>
    </div>
    <a-card :bordered="false" style="margin-top: 10px">
      <a-form :form="form" layout="horizontal">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="24">
            <a-form-item label="发布人">
              {{releaseFormItem.article.creater}}
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="24">
            <a-form-item label="发布日期">
              {{releaseFormItem.article.dateCreated}}
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider style="margin-top: 10px"/>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="{span:24}" :md="{span: 12}" :sm="24">
            <a-form-item label="简述">
              {{releaseFormItem.article.sketch}}
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider style="margin-top: 10px"/>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="{span:24}" :md="{span: 12}" :sm="24">
            <div v-html="releaseFormItem.article.content"></div>
          </a-col>
        </a-row>
        <a-divider style="margin-top: 10px"/>
      </a-form>
    </a-card>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import releaseArticleVO from '../../../api/vo/blog/releaseArticleVO'
  import PageLayout from '@/components/page/PageLayout'

  export default {
    name: 'Detail',
    components: {
      PageLayout
    },
    data () {
      return {
        releaseFormItem: releaseArticleVO(),
        form: this.$form.createForm(this),
        instanceId: ''
      }
    },
    mounted () {
      this.instanceId = eval(this.$router.currentRoute.query['id'])
      if (this.instanceId) {
        this.loadData(this.instanceId)
      }
      console.info('123', this.instanceId)
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.article.list.view,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.releaseFormItem, data.body)
          }
        })
      }
    }
  }
</script>